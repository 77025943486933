export function Spacer({
  size,
}: {
  /**
   * The size of the space
   *
   * 5xs: h-2 (8px)
   *
   * 4xs: h-4 (16px)
   *
   * 3xs: h-8 (32px)
   *
   * 2xs: h-12 (48px)
   *
   * xs: h-16 (64px)
   *
   * sm: h-20 (80px)
   *
   * md: h-24 (96px)
   *
   * lg: h-28 (112px)
   *
   * xl: h-32 (128px)
   *
   * 2xl: h-36 (144px)
   *
   * 3xl: h-40 (160px)
   *
   * 4xl: h-44 (176px)
   */
  size:
    | '5xs'
    | '4xs'
    | '3.5xs'
    | '3xs'
    | '2xs'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
}) {
  const options: Record<typeof size, string> = {
    '5xs': 'min-h-[.5rem]',
    '4xs': 'min-h-[1rem]',
    '3.5xs': 'min-h-[1.5rem]',
    '3xs': 'min-h-[2rem]',
    '2xs': 'min-h-[3rem]',
    xs: 'min-h-[4rem]',
    sm: 'min-h-[5rem]',
    md: 'min-h-[6rem]',
    lg: 'min-h-[7rem]',
    xl: 'min-h-[8rem]',
    '2xl': 'min-h-[9rem]',
    '3xl': 'min-h-[10rem]',
    '4xl': 'min-h-[11rem]',
  }
  const className = options[size]
  return <div className={className} />
}
